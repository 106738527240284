/**
 * @author : Herlangga Sefani <https://github.com/gaibz>
 */

'use strict';

const axios = require("axios");
const errorHandler = require("@app/lib/ErrorHandler");

function identify(roles=null, auto_redirect = true) {
    if(!roles) {
        roles = window.config.path;
    }
    return new Promise((resolve, reject) => {
        if(!localStorage.getItem('api-key')) {
            if(auto_redirect) {
                console.log("API KEY NOT FOUND !");
                return window.location.href = window.config.site_url + roles + '/login';
            }
            return reject();
        }
        axios({
            method : 'get',
            url : window.config.site_url + 'api/auth/identify',
            headers : {
                'key' : localStorage.getItem('api-key')
            }
        }).then((resp) => {
            let data = resp.data;
            let user = data.user;

            if(!data.success) {
                if (auto_redirect) {
                    return window.location.href = window.config.site_url + roles + '/login';
                }
                return reject();
            }

            if(roles) {
                roles = roles.toLowerCase();
                if (user.roles.toLowerCase() !== roles) {
                    if(auto_redirect) {
                        return window.location.href = window.config.site_url + roles + '/login'
                    }
                    return reject();
                }
            }

            localStorage.setItem('user', JSON.stringify(user));
            resolve(user);
        }).catch(() => {
            console.log("Api Key error");
            if (auto_redirect) {
                return window.location.href = window.config.site_url + roles + '/login';
            }
            return reject();
        });
    })

}

module.exports = {identify}