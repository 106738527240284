/**
 * @author : Herlangga Sefani <https://github.com/gaibz>
 */

'use strict';

import Header from './Header.svelte';

const header = new Header({
    target : document.getElementById('app-header'),
    props : {}
});

window.header = header;

export default header;