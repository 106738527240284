/**
 * @author : Herlangga Sefani <https://github.com/gaibz>
 */

'use strict';

/**
 * Parse Object into Query String
 * @param obj
 * @param prefix
 * @returns {string}
 */
function toQueryString(obj, prefix = null) {
    let str = [], p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                toQueryString(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

/**
 * Build query for filter parameter
 * usage :
 * <code>
 * let filter = {
 *     field : value, // equals
 *     field : {
 *         min_equals : value,
 *         max_equals : value,
 *         min : value,
 *         max : value,
 *         between : [value_min, value_max],
 *         in_array : [value1, value2, value3]
 *     },
 * }
 * filter = FilterDriver.buildQuery(filter);
 * </code>
 * @param query {Object}
 * @return {string}
 */
function buildFilter(query = {}) {
    let filter = [];
    for ( let q in query ) {
        if ( query.hasOwnProperty(q) === false ) continue;

        if( typeof query[q] === 'object' ) {
            let qparam = query[q];
            if ( typeof qparam.min_equals !== 'undefined' ) {
                filter.push(`${q}:>=${qparam.min_equals}`);
            }
            if ( typeof qparam.max_equals !== 'undefined' ) {
                filter.push(`${q}:<=${qparam.max_equals}`);
            }
            if ( typeof qparam.min !== 'undefined' ) {
                filter.push(`${q}:>${qparam.min}`);
            }
            if ( typeof qparam.max !== 'undefined' ) {
                filter.push(`${q}:<${qparam.max}`);
            }
            if ( typeof qparam.between !== 'undefined' ) {
                if ( qparam.between[0] && qparam.between[1] ) {
                    filter.push(`${q}:${qparam.between[0]}-${qparam.between[1]}`);
                }
                else if ( qparam.between[0] ) {
                    filter.push(`${q}:>=${qparam.between[0]}`);
                }
                else if ( qparam.between[1] ) {
                    filter.push(`${q}:<=${qparam.between[1]}`);
                }
            }
            if ( typeof qparam.in_array !== 'undefined' ) {
                filter.push(`${q}:${qparam.in_array.join(',')}`);
            }
        }
        else
        {
            filter.push(`${q}:${query[q]}`);
        }
    }
    return filter.join(";");
}
module.exports = {toQueryString, buildFilter}