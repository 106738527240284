<script>
    import {onMount} from "svelte";
    import axios from "axios";
    import Query from "@app/lib/Query";
    import errorHandler from "@app/lib/ErrorHandler";
    import Id from "@app/lib/Id";

    let navbar_title = window.config.navbar_title || '';
    let navbar_menus = window.config.navbar_menus || [];
    let user = {};
    let roles = "";
    let account_menu = window.config.account_menu || 0;

    let my_api_key = localStorage.getItem('api-key');

    function logout() {
        localStorage.removeItem('api-key');
        let user = localStorage.getItem('user');
        localStorage.removeItem('user');
        user = JSON.parse(user);
        let roles = user.roles;
        window.location.href = window.config.site_url + roles.toLowerCase() + '/login';
    }

    function modalCredentials() {
        window.jQuery("#modal-update-password").modal('show');
    }

    function updateCredentials() {
        axios({
            method : 'post',
            url : window.config.site_url + 'api/auth/update',
            data : Query.toQueryString(user),
            headers : {
                'key' : localStorage.getItem('api-key')
            }
        }).then((resp) => {
            let data = resp.data;
            if(!data.success) return errorHandler.apiError(data);

            window.toastr.success("Credentials diperbarui", "Berhasil");
            window.jQuery("#modal-update-password").modal("hide");
            window.location.href = window.config.site_url + roles.toLowerCase() + '/login';
        }).catch(errorHandler.httpError);
    }

    function changeApiKey() {
        axios({
            method : 'get',
            url : window.config.site_url + 'api/auth/changeapikey',
            headers : {
                'key' : localStorage.getItem('api-key')
            }
        }).then((resp) => {
            let data = resp.data;
            if(!data.success) return errorHandler.apiError(data);
            localStorage.setItem('api-key', data.key);
            my_api_key = data.key;
        }).catch(errorHandler.httpError);
    }

    onMount(() => {
        if(account_menu) {
            Id.identify().then((user_data) => {
                user = user_data;
                roles = user.roles;
            });
        }
    })
</script>
<div>

    <div class="container text-white">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none px-2">
                <h4>{navbar_title}</h4>
            </a>

            <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                {#each navbar_menus as navbar}
                    <li>
                        <a href={navbar.url} class="nav-link px-2 {navbar.active ? 'text-white' : 'text-secondary'}">
                            {navbar.title}
                            {#if navbar.active}
                                <span class="sr-only">(current)</span>
                            {/if}
                        </a>
                    </li>
                {/each}

            </ul>

            {#if account_menu}
                <div class="dropdown text-end">
                    <a class="d-block text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-user"></i> {user.username}
                    </a>
                    <div class="dropdown-menu text-small" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-show-apikey">My API Key</a>
                        <a class="dropdown-item" href="#" on:click={modalCredentials}>Change Credentials</a>
                        <a class="dropdown-item" href="#" on:click={logout}>Logout</a>
                    </div>
                </div>
            {/if}
        </div>
    </div>


    <div class="modal fade" tabindex="-1" role="dialog" id="modal-update-password">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Change Credentials</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning">
                        Changing this setting will make you need to re-login !
                    </div>
                    {#if roles === 'ADMIN'}
                        <div class="form-group">
                            <label>Username : </label>
                            <input type="text" class="form-control" bind:value={user.username} />
                        </div>
                    {/if}
                    <div class="form-group">
                        <label>Password : </label>
                        <input type="password" class="form-control" bind:value={user.password} />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa fa-close"></i>
                        Close
                    </button>
                    <button type="button" class="btn btn-primary" on:click={updateCredentials}>
                        <i class="fa fa-check-circle"></i>
                        Change
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" tabindex="-1" role="dialog" id="modal-show-apikey">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">My API KEY</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning">
                        Don't share your API KEY with anyone You didn't trust !
                    </div>
                    <div class="input-group">
                        <input type="text" class="form-control" value={my_api_key} />
                        <div class="input-group-btn">
                            <button class="btn btn-danger" on:click={changeApiKey}>Change</button>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa fa-close"></i>
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
